/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { BundleSource } from '../../util/bundle_reader';
import { toByteStreamReaderHelper } from '../../util/byte_stream';

/**
 * On web, a `ReadableStream` is wrapped around by a `ByteStreamReader`.
 */
export function toByteStreamReader(
  source: BundleSource,
  bytesPerRead: number
): ReadableStreamReader<Uint8Array> {
  if (source instanceof Uint8Array) {
    return toByteStreamReaderHelper(source, bytesPerRead);
  }
  if (source instanceof ArrayBuffer) {
    return toByteStreamReaderHelper(new Uint8Array(source), bytesPerRead);
  }
  if (source instanceof ReadableStream) {
    return source.getReader();
  }
  throw new Error(
    'Source of `toByteStreamReader` has to be a ArrayBuffer or ReadableStream'
  );
}
